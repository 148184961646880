import React from 'react'
import Container from './container'
import SlickSlider from './slick-slider'
import Img from 'gatsby-image'

const ImageSlider = ({images, ...rest}) => {

	const settings = {
		slidesToShow: 2,
		slidesToScroll: 2,
		autoplay: true,
		autoplaySpeed: 6000,
		infinite: true,
		dots: true
	}

	return(

		<Container>
			<SlickSlider settings={settings} >
				{images.map((item,index) => (
						<Img fluid={item.fluid} key={index} />
					))}
			</SlickSlider>
		</Container>

	)

}

export default ImageSlider