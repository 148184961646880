import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Container from '../components/container'
import { Box, Divider, Heading } from '@chakra-ui/react'
import ImageSlider from '../components/image-slider'

export const query = graphql`
	query {
		page: contentfulPage(slug: {eq: "sobre"}) {
			...aboutPageData
		}
	}
`

const ContentfulTemplateAbout = ({data, ...rest}) => {

	const { page } = data

	return (
		<Layout>
			<SEO 
				title={page.title}
				description={page.metadescription.metadescription}
			/>
			<Box py={32}>
				<Container>
					<Box pt={16} pb={8} textAlign="center">
						<Heading as="h1">{page.template.heading}</Heading>
						<Divider my={2} />
						<Box fontSize="lg" dangerouslySetInnerHTML={{__html: page.template.content.content}} />
					</Box>
					<ImageSlider images={page.template.images} />
				</Container>
			</Box>
		</Layout>
		)
}

export default ContentfulTemplateAbout